// Update if you would like to force a build of this service without changing any logic
// Force CI build: 2025-02-07
import '@/styles/globals.css';
import '@/styles/animations.css';
import '@/styles/react-day-picker.css';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { ReactElement, ReactNode } from 'react';

import { AnalyticsProvider, PageView } from '@packfleet/analytics';
import { FeatureFlagsProvider } from '@packfleet/feature-flags';

import { initializeDateFns } from '@/utilities/date';
import { initializeFirebase } from '@/utilities/firebase';
import { initializePolyfills } from '@/utilities/polyfills';
import { ErrorPage } from '../components/error/ErrorPage';
import { useApollo } from '../graphql/client';

initializeFirebase();
initializeDateFns();
initializePolyfills();

const mockingEnabled = process.env.NEXT_PUBLIC_API_MOCKING === 'enabled';

if (mockingEnabled) {
  require('../mocks');
}

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);
  const getLayout =
    Component.getLayout ?? ((page) => <PageView>{page}</PageView>);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <AnalyticsProvider apiKey={process.env.NEXT_PUBLIC_POSTHOG_API_KEY ?? ''}>
        <FeatureFlagsProvider
          apiKey={process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT ?? ''}
          streaming={mockingEnabled ? false : undefined}
        >
          <ApolloProvider client={apolloClient}>
            {getLayout(<Component {...pageProps} />)}
          </ApolloProvider>
        </FeatureFlagsProvider>
      </AnalyticsProvider>
    </Sentry.ErrorBoundary>
  );
}
