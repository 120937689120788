// Log any GraphQL errors or network error that occurred
// and report to sentry
import { ApolloLink } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { GraphQLError } from 'graphql';
import {
  CurrentUserDocument,
  CurrentUserQuery,
  CurrentUserQueryVariables,
} from '../generated/graphql';
import { apolloClient } from './client';

export const logoutLink = (onLogout: () => void) =>
  onError((e) => {
    if (
      e.graphQLErrors?.some(
        (e) =>
          e.extensions?.code === 'FORBIDDEN' ||
          e.extensions?.code === 'UNAUTHENTICATED',
      )
    ) {
      onLogout();
    }
  });

export const invalidSecurityCodeInterceptor = (
  onInvalidSecurityCode: () => void,
) =>
  new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const data =
        typeof response === 'object' &&
        response != null &&
        'data' in response &&
        typeof response.data === 'object' &&
        response.data != null
          ? response.data
          : {};

      const error = Object.values(data).find(
        (res) =>
          typeof res === 'object' &&
          res != null &&
          'error' in res &&
          typeof res.error === 'object' &&
          res.error != null &&
          'code' in res.error &&
          res.error.code === 'invalid-security-code',
      );

      if (error) {
        onInvalidSecurityCode();
      }

      return response;
    });
  });

export const errorLink = onError((e) => {
  // get cached user so we can send details to sentry if it exists
  // Don't capture during SSR because we capture the errors separately on the server.
  if (typeof window === undefined) return;
  const user = apolloClient?.cache.readQuery<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >({
    query: CurrentUserDocument,
    variables: {},
  });
  if (user && user.currentUser?.user?.email) {
    Sentry.setUser({
      id: user.currentUser?.user?.id,
      email: user.currentUser?.user?.email,
    });
  }
  Sentry.configureScope((scope) =>
    scope.setTag(
      'request_id',
      e.operation.getContext().headers['X-Request-Id'],
    ),
  );

  captureApolloError(e.graphQLErrors, e.networkError);
});

export function captureApolloError(
  graphQLErrors?: ErrorResponse['graphQLErrors'],
  networkError?: ErrorResponse['networkError'],
) {
  graphQLErrors?.forEach((e) => {
    if (
      e.extensions?.code === 'FORBIDDEN' ||
      e.extensions?.code === 'UNAUTHENTICATED'
    ) {
      // Don't report 401 or 403 errors.
      return;
    }
    Sentry.captureException(
      new GraphQLError(
        e.message,
        e.nodes,
        e.source,
        e.positions,
        e.path,
        e.originalError,
        e.extensions,
      ),
    );
  });
  if (networkError) {
    Sentry.captureException(networkError);
  }
}
